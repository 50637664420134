import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  alpha_spaces as rule_alpha_spaces,
  length as rule_length,
  max as rule_max,
  numeric as rule_numeric,
} from 'vee-validate/dist/rules'
import VueI18n from 'vue-i18n'
import vi from 'vee-validate/dist/locale/vi.json'
import en from 'vee-validate/dist/locale/en.json'
import cn from 'vee-validate/dist/locale/zh_CN.json'
// import en from './locale/en.json'
// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorMoneyPayout,
  validatePhoneNumber, validatorMoneyPayment,
} from './validators'

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en',
  messages: {
    en: {
      validations: {
        ...en.messages,
        password: 'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter and one special character',
        moneyPayment: 'The amount is invalid',
        url_callback: 'The "URL Callback" is invalid',
        not_valid: 'not valid',
      },
    },
    vi: {
      validations: {
        ...vi.messages,
        password: 'Mật khẩu phải có ít nhất 8 ký tự, bao gồm ít nhất một chữ số, một chữ cái viết hoa, một chữ cái viết thường và một ký tự đặc biệt',
        moneyPayment: 'Số tiền không hợp lệ',
        required: 'Vui lòng không bỏ trống ô này',
        url_callback: '"URL Callback" không đúng định dạng',
        not_valid: 'không hợp lệ',
      },
    },
    zh_CN: {
      validations: {
        ...cn.messages,
        password: 'Mật khẩu phải có ít nhất 8 ký tự, bao gồm ít nhất một chữ số, một chữ cái viết hoa, một chữ cái viết thường và một ký tự đặc biệt',
        moneyPayment: '金额不合格',
        required: '请不要跳过此框',
        url_callback: '"URL Callback" 的格式不正确',
        confirmed: '密码不匹配',
        not_valid: '不合格',
      },
    },
  },
})

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', {
  // eslint-disable-next-line camelcase
  ...rule_required,
  message: i18n.t('validations.not_valid'),
})

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', {
  // eslint-disable-next-line camelcase
  ...rule_confirmed,
  message: '密码不匹配',
})

export const regex = extend('regex', {
  // eslint-disable-next-line camelcase
  ...rule_regex,
  message: i18n.t('validations.not_valid'),
})

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', {
  // eslint-disable-next-line camelcase
  ...rule_integer,
  message: i18n.t('validations.integer'),
})

export const numeric = extend('numeric', rule_numeric)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', {
  // eslint-disable-next-line camelcase
  ...rule_alpha_dash,
  message: '{_field_} không hợp lệ',
})

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const alphaSpaces = extend('alpha-spaces', {
  // eslint-disable-next-line camelcase
  ...rule_alpha_spaces,
  message: `{_field_} ${i18n.t('validations.not_valid')}`,
})

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: (_, values) => i18n.t('validations.password', values),
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: i18n.t('validations.url_callback'),
})

export const moneyPayout = extend('money-pay-out', {
  validate: validatorMoneyPayout,
  message: 'Số tiền phải lớn hơn 5.000.000',
})

export const moneyPayment = extend('money-payment', {
  validate: validatorMoneyPayment,
  message: i18n.t('validations.moneyPayment'),
})

export const phoneNumber = extend('phone-number', {
  validate: validatePhoneNumber,
  message: 'Số điện thoại không đúng định dạng',
})

// Install English and vietnam localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  vi: {
    messages: vi.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  zh_CN: {
    messages: cn.messages,
    names: {},
    fields: {},
  },
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
