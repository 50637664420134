<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-card
      header-tag="header"
    >
      <template #header>
        <h3 class="mb-0">
          {{ $t('Ví USDT') }}
        </h3>
        <h4>
          {{ $t('Số dư') }}: <b style="color: darkgreen">{{ statistic.usdt_amount.toLocaleString() || 0 }}</b> USDT
        </h4>
        <b-button
          href="#"
          variant="primary"
          @click="resetForm && $bvModal.show('bv-modal-usdt')"
        >
          {{ $t('Rút tiền') }}
        </b-button>
      </template>
    </b-card>

    <b-card header-tag="header">
      <template #header>
        <h3 class="mb-0">
          {{ $t('Lịch sử giao dịch') }}
        </h3>
      </template>
      <b-row cols="4">
        <b-col>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              :placeholder="$t('Mã giao dịch/ Nội dung')"
            />
          </b-input-group>
        </b-col>

        <b-col cols="2">
          <b-form-datepicker
            v-model="dateFrom"
            local="vn"
            :placeholder="$t('Từ ngày')"
            selected-variant="primary"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>

        <b-col cols="2">
          <b-form-datepicker
            v-model="dateTo"
            local="vn"
            :placeholder="$t('Đến ngày')"
            selected-variant="primary"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>

        <b-col cols="2">
          <b-form-group>
            <v-select
              v-model="type"
              :placeholder="$t('Loại thanh toán')"
              label="text"
              :options="typeOptions"
              :reduce="item => item.value"
            />
          </b-form-group>
        </b-col>

        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="fetchHistory(true)"
          >
            {{ $t('Tìm kiếm') }}
          </b-button>
        </b-col>

        <b-col cols="auto">
          <b-button
            variant="outline-primary"
            @click="exportOrder"
          >
            {{ $t('Export') }}
          </b-button>
        </b-col>
      </b-row>

      <b-table
        small
        :fields="fields"
        :items="items"
        responsive
        class="mt-2"
        bordered
        :busy="isTableLoading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(transactionId)="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
        <template #cell(updatedAt)="data">
          <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
        </template>
        <template #cell(amountBeforeChange)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>
        <template #cell(amountChange)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>
        <template #cell(amountAfterChange)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>
      </b-table>

      <b-row>
        <b-col class="d-flex justify-content-md-end">
          <b-pagination
            v-model="pagination.current_page"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
            :first-text="$t('Trang đầu')"
            :prev-text="$t('Trang trước')"
            :next-text="$t('Trang tiếp')"
            :last-text="$t('Trang cuối')"
            @change="changePage"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="bv-modal-usdt"
      ref="createOrderModal"
      hide-footer
    >
      <template #modal-title>
        {{ $t('Rút tiền') }}
      </template>
      <validation-observer
        ref="createOrderForm"
        #default="{invalid}"
      >
        <b-form
          @submit.prevent="withdrawUsdt"
        >
          <div class="d-block text-center">
            <b-form-group
              :label="$t('Số dư')"
              label-for="usdt_amount_total"
            >
              <b-input-group
                append="USDT"
              >
                <b-form-input
                  id="usdt_amount_total"
                  v-model="statistic.usdt_amount"
                  disabled
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              :label="$t('Số lượng')"
              label-for="usdt_amount"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Số lượng')"
                vid="amount"
                rules="required|money-payment"
              >
                <b-input-group
                  append="USDT"
                >
                  <b-form-input
                    id="usdt_amount"
                    v-model="usdtAmount"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Địa chỉ ví USDT')"
              label-for="usdt_address"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Địa chỉ ví USDT')"
                vid="usdt_address"
                rules="required"
              >
                <b-form-textarea
                  id="usdt_address"
                  v-model="usdtAddress"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Nơi nhận')"
              label-for="output"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Nơi nhận')"
                vid="output"
                rules="required"
              >
                <v-select
                  id="output"
                  v-model="output"
                  :placeholder="$t('Liên kết USDT')"
                  label="label"
                  :options="outputOptions"
                  :reduce="item => item.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('Ghi chú')"
              label-for="note"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Ghi chú')"
                vid="note"
              >
                <b-form-textarea
                  id="note"
                  v-model="note"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <b-button
            class="mt-3"
            block
            variant="primary"
            :disabled="invalid"
            @click="showConfirmModal"
          >
            {{ $t('Rút tiền') }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="bv-modal-usdt-confirm"
      ref="confirmModal"
      hide-footer
      size="lg"
    >
      <template #modal-title>
        {{ $t('Rút tiền') }}
      </template>
      <div>
        <table style="table-layout: fixed; width: 100%">
          <tr>
            <td style="min-width: 150px">
              {{ $t('Địa chỉ ví USDT:') }}
            </td>
            <td style="word-wrap: break-word">
              {{ usdtAddress }}
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Liên kết USDT:') }}
            </td>
            <td style="word-wrap: break-word">
              {{ output }}
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Ghi chú:') }}
            </td>
            <td style="word-wrap: break-word">
              {{ note }}
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Số lượng') }}:
            </td>
            <td style="word-wrap: break-word">
              {{ usdtAmount }} USDT
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Phí') }}:
            </td>
            <td style="word-wrap: break-word">
              {{ user ? user.usdtOutFee : 0 }} USDT
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Thực nhận') }}:
            </td>
            <td style="word-wrap: break-word">
              {{ user ? parseInt(usdtAmount) - parseInt(user.usdtOutFee) : 0 }} USDT
            </td>
          </tr>
        </table>
        <b-button
          class="mt-3"
          block
          variant="primary"
          @click="withdrawUsdt"
        >
          {{ $t('Xác nhận') }}
        </b-button>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BCard,
  BTable,
  BPagination,
  BOverlay,
  BInputGroup,
  BFormTextarea,
  BFormDatepicker,
  BInputGroupPrepend,
  BSpinner,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, moneyPayout, integer, moneyPayment,
} from '@validations'
import { formatDateTimeDb } from '@/libs/timezone'
// eslint-disable-next-line import/no-cycle
import { useI18n } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import Swal from 'sweetalert2'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/no-cycle
import {
  useTransactionHistoryUsdt, useCreateWithdrawal, useMasterFilter,
} from '@/views/order-management/useOrder'

export default {
  name: 'Wallet',
  components: {
    BInputGroupPrepend,
    BFormDatepicker,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCard,
    BTable,
    BPagination,
    BOverlay,
    BFormTextarea,
    BSpinner,
  },
  setup() {
    const isTableLoading = ref(false)
    const createOrderForm = ref(null)
    const createOrderModal = ref(null)
    const confirmModal = ref(null)
    const { t } = useI18n()
    const showOverlay = ref(false)
    const usdtAmount = ref(null)
    const usdtAddress = ref(null)
    const output = ref('ERC20')
    const note = ref(null)
    const user = ref(null)
    const outputOptions = ref([
      {
        label: 'ERC20',
        value: 'ERC20',
      },
      {
        label: 'TRC20',
        value: 'TRC20',
      },
    ])
    const {
      fetchWithdrawStatistic,
    } = useMasterFilter()

    const {
      createWithdrawal,
    } = useCreateWithdrawal()

    const statistic = ref({
      total_payin: 0,
      total_payout: 0,
      total_bank: 0,
      total_amount: 0,
      usdt_amount: '___',
    })

    const {
      merchantName,
      merchantNameOptions,
      dateFrom,
      dateTo,
      payGate,
      payGateOptions,
      orderStatus,
      orderStatusOptions,
      fetchMerchantNameOptions,
    } = useMasterFilter()

    const {
      search,
      fields,
      items,
      pagination,
      fetchBalanceHistory,
    } = useTransactionHistoryUsdt()

    const type = ref(null)
    const typeOptions = ref([
      { value: 'recharge', text: t('Nạp tiền') },
      { value: 'withdraw_money', text: t('Rút tiền') },
      { value: 'add_money', text: t('Cộng tiền') },
      { value: 'sub_money', text: t('Trừ tiền') },
    ])

    const getWithdrawStatistic = () => {
      fetchWithdrawStatistic({
        type: null,
      }).then(res => {
        statistic.value = res
      })
    }

    const reloadWallet = () => {
      getWithdrawStatistic()
    }

    const resetForm = () => {
      usdtAmount.value = null
      usdtAddress.value = null
      output.value = 'ERC20'

      return true
    }

    const withdrawUsdt = () => {
      showOverlay.value = true
      createWithdrawal({
        amount: usdtAmount.value,
        from: null,
        to: usdtAddress.value,
        bank_id: output.value,
        note: note.value,
      }).then(() => {
        Swal.fire({
          icon: 'success',
          title: t('Thành công'),
          text: t('Tạo lệnh rút tiền thành công'),
          confirmButtonText: t('Đóng'),
        })
        createOrderForm.value.reset()
        resetForm()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: t('Thất bại'),
          text: t(err.response.data.message),
          confirmButtonText: t('Đóng'),
        })
      }).finally(() => {
        showOverlay.value = false
        confirmModal.value.hide()
        createOrderModal.value.hide()
        reloadWallet()
      })
    }

    const showConfirmModal = () => {
      const totalAmount = parseFloat(statistic.value.usdt_amount)
      const amount = parseFloat(usdtAmount.value)
      if (amount > totalAmount) {
        Swal.fire({
          icon: 'error',
          title: t('Thất bại'),
          text: t('Số dư USDT không đủ'),
          confirmButtonText: t('Đóng'),
        })
        return
      }

      const realAmount = parseFloat(usdtAmount.value) - parseFloat(user.value.usdtOutFee)

      if (realAmount <= 0) {
        Swal.fire({
          icon: 'error',
          title: t('Thất bại'),
          text: t('Số tiền không được nhỏ hơn phí rút!'),
          confirmButtonText: t('Đóng'),
        })
        return
      }
      createOrderModal.value.hide()
      confirmModal.value.show()
    }

    const fetchHistory = (isSearch = false) => {
      isTableLoading.value = true
      fetchBalanceHistory({
        search: search.value,
        page: isSearch ? 1 : pagination.value.current_page,
        date_from: dateFrom.value,
        date_to: dateTo.value,
        type: 'payin_usdt',
        status: orderStatus.value,
        trans_type: type.value,
      }).then(response => {
        items.value = response.data
        pagination.value = response.meta.pagination
      })
        .finally(() => {
          isTableLoading.value = false
        })
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchHistory()
    }

    onMounted(() => {
      getWithdrawStatistic()
      useJwt.getProfile()
        .then(response => {
          user.value = response.data.data
        })
      fetchHistory()
    })

    return {
      showOverlay,
      statistic,
      reloadWallet,

      usdtAmount,
      usdtAddress,
      output,
      outputOptions,
      withdrawUsdt,
      resetForm,
      user,
      showConfirmModal,
      createOrderModal,
      confirmModal,
      note,

      // rules
      required,
      moneyPayout,
      moneyPayment,
      formatDateTimeDb,
      integer,

      merchantName,
      merchantNameOptions,
      dateFrom,
      dateTo,
      payGate,
      payGateOptions,
      type,
      typeOptions,
      search,
      orderStatus,
      orderStatusOptions,
      isTableLoading,

      fields,
      items,
      pagination,

      changePage,
      fetchHistory,
      fetchMerchantNameOptions,
    }
  },
  methods: {
    exportOrder() {
      this.showOverlay = true
      this.$http.get('/export/order-history', {
        responseType: 'blob',
        params: {
          search: this.search,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          type: 'payin_usdt',
          status: this.orderStatus,
          trans_type: this.type,
        },
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xls' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'usdt-export.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
        .finally(() => {
          this.showOverlay = false
        })
    },
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
